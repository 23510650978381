















































































































































import {
  computed,
  defineComponent,
  reactive,
  watch,
} from "@vue/composition-api";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        // invoiceModuleEnabled: false,
        companyModuleEnabled: false,
        abstractModuleEnabled: false,
        budgetModuleEnabled: false,
        posterSessionModuleEnabled: false,
        surveyModuleEnabled: false,
        printModuleEnabled: false,
        additionalServiceModuleEnabled: false,
        accommodationModuleEnabled: false,
      }),
    },
  },

  setup(props, { emit }) {
    const model = reactive({
      // invoiceModuleEnabled: false,
      companyModuleEnabled: false,
      abstractModuleEnabled: false,
      budgetModuleEnabled: false,
      posterSessionModuleEnabled: false,
      surveyModuleEnabled: false,
      printModuleEnabled: false,
      additionalServiceModuleEnabled: false,
      accommodationModuleEnabled: false,
    });

    const isAllChecked = computed(
      () => Object.values(model).filter((m) => !m).length <= 0
    );
    const isAllIndeterminate = computed(
      () =>
        Object.values(model).filter((m) => !m).length <
        Object.values(model).length
    );

    const onAll = () => {
      const isAnyUnchecked = Object.values(model).filter((m) => !m).length > 0;

      if (isAnyUnchecked) {
        // model.invoiceModuleEnabled = true;
        model.companyModuleEnabled = true;
        model.abstractModuleEnabled = true;
        model.budgetModuleEnabled = true;
        model.posterSessionModuleEnabled = true;
        model.surveyModuleEnabled = true;
        model.printModuleEnabled = true;
        model.additionalServiceModuleEnabled = true;
        model.accommodationModuleEnabled = true;
      } else {
        // model.invoiceModuleEnabled = false;
        model.companyModuleEnabled = false;
        model.abstractModuleEnabled = false;
        model.budgetModuleEnabled = false;
        model.posterSessionModuleEnabled = false;
        model.surveyModuleEnabled = false;
        model.printModuleEnabled = false;
        model.additionalServiceModuleEnabled = false;
        model.accommodationModuleEnabled = false;
      }
    };

    watch(
      () => model,
      (newModel) => emit("input", newModel),
      { deep: true }
    );
    watch(
      () => props.value,
      (newValue) => {
        // model.invoiceModuleEnabled = newValue.invoiceModuleEnabled;
        model.companyModuleEnabled = newValue.companyModuleEnabled;
        model.abstractModuleEnabled = newValue.abstractModuleEnabled;
        model.budgetModuleEnabled = newValue.budgetModuleEnabled;
        model.posterSessionModuleEnabled = newValue.posterSessionModuleEnabled;
        model.surveyModuleEnabled = newValue.surveyModuleEnabled;
        model.printModuleEnabled = newValue.printModuleEnabled;
        model.additionalServiceModuleEnabled =
          newValue.additionalServiceModuleEnabled;
        model.accommodationModuleEnabled = newValue.accommodationModuleEnabled;
      },
      { deep: true }
    );

    return { model, isAllChecked, isAllIndeterminate, onAll };
  },
});
