var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{staticClass:"o-event-modules"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-checkbox',{attrs:{"prepend-icon":"mdi-all-inclusive","label":_vm.$t('layout.misc.selectAll'),"value":_vm.isAllChecked,"indeterminate":_vm.isAllIndeterminate},on:{"change":_vm.onAll}}),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-checkbox',{attrs:{"prepend-icon":"mdi-domain","label":_vm.$t('event.panel.sidebar.company.title')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('panel.event.edit.info.crsData')))])])]},proxy:true}]),model:{value:(_vm.model.companyModuleEnabled),callback:function ($$v) {_vm.$set(_vm.model, "companyModuleEnabled", $$v)},expression:"model.companyModuleEnabled"}}),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-checkbox',{attrs:{"prepend-icon":"mdi-card-text","label":"Streszczenia"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('panel.event.edit.info.abstractsDetails')))])])]},proxy:true}]),model:{value:(_vm.model.abstractModuleEnabled),callback:function ($$v) {_vm.$set(_vm.model, "abstractModuleEnabled", $$v)},expression:"model.abstractModuleEnabled"}}),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-checkbox',{attrs:{"prepend-icon":"mdi-currency-usd","label":_vm.$t('event.panel.sidebar.budget.title')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('panel.event.edit.info.budgetDetails')))])])]},proxy:true}]),model:{value:(_vm.model.budgetModuleEnabled),callback:function ($$v) {_vm.$set(_vm.model, "budgetModuleEnabled", $$v)},expression:"model.budgetModuleEnabled"}}),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-checkbox',{attrs:{"prepend-icon":"mdi-room-service-outline","label":_vm.$t('layout.misc.additionalServices')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('panel.event.edit.info.additionalServicesModuleInfo')))])])]},proxy:true}]),model:{value:(_vm.model.additionalServiceModuleEnabled),callback:function ($$v) {_vm.$set(_vm.model, "additionalServiceModuleEnabled", $$v)},expression:"model.additionalServiceModuleEnabled"}}),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-checkbox',{attrs:{"prepend-icon":"mdi-bed-outline","label":_vm.$t('layout.misc.accommodation')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('panel.event.edit.info.accommodationModuleInfo')))])])]},proxy:true}]),model:{value:(_vm.model.accommodationModuleEnabled),callback:function ($$v) {_vm.$set(_vm.model, "accommodationModuleEnabled", $$v)},expression:"model.accommodationModuleEnabled"}}),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-checkbox',{attrs:{"prepend-icon":"mdi-presentation-play","label":_vm.$t('event.panel.sidebar.poster.title')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('panel.event.modules.sentWorksManagement')))])])]},proxy:true}]),model:{value:(_vm.model.posterSessionModuleEnabled),callback:function ($$v) {_vm.$set(_vm.model, "posterSessionModuleEnabled", $$v)},expression:"model.posterSessionModuleEnabled"}}),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-checkbox',{attrs:{"prepend-icon":"mdi-comment-question-outline","label":_vm.$t('event.panel.sidebar.survey.title')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('panel.event.edit.info.pollsQuestionsAnswers')))])])]},proxy:true}]),model:{value:(_vm.model.surveyModuleEnabled),callback:function ($$v) {_vm.$set(_vm.model, "surveyModuleEnabled", $$v)},expression:"model.surveyModuleEnabled"}}),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-checkbox',{attrs:{"prepend-icon":"mdi-printer","label":_vm.$t('event.panel.sidebar.print.title')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('panel.event.edit.info.pcipCollective')))])])]},proxy:true}]),model:{value:(_vm.model.printModuleEnabled),callback:function ($$v) {_vm.$set(_vm.model, "printModuleEnabled", $$v)},expression:"model.printModuleEnabled"}}),_c('v-divider')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }